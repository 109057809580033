export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: '標籤管理',
        to: '/tag',
        icon: 'cil-tags',
        roles: ['admin'],
      },
      {
        _name: 'CSidebarNavItem',
        name: '單位管理',
        to: '/organization',
        icon: 'cil-building',
        roles: ['admin'],
      },
      {
        _name: 'CSidebarNavItem',
        name: '分類管理',
        to: '/category',
        icon: 'cil-layers',
        roles: ['admin'],
      },
      {
        _name: 'CSidebarNavItem',
        name: '音檔管理',
        to: '/audio',
        icon: 'cil-music-note',
        roles: ['admin','org-admin','org-member'],
      },
      {
        _name: 'CSidebarNavItem',
        name: '群組管理',
        to: '/group',
        icon: 'cil-people',
        roles: ['admin','org-admin','org-member'],
      },
      /*
      {
        _name: 'CSidebarNavItem',
        name: '月光機管理',
        to: '/moonlight-device',
        icon: 'cil-moon',
        roles: ['admin','org-admin','org-member'],
      },
      */
      {
        _name: 'CSidebarNavDropdown',
        name: '人員管理',
        route: '/account',
        to: '/account',
        icon: 'cil-user',
        items: [
          {
            name: '列表',
            to: '/account',
            icon: 'cil-list',
            roles: ['admin','org-admin','org-member'],
          },
          {
            name: '匯入',
            to: '/account/import',
            roles: ['admin','org-admin'],
            icon: 'cil-cloud-upload'
          },
          {
            name: '月映心湖統計',
            to: '/account/ReportCjxf',
            roles: ['admin'],
            icon: 'cil-heart'
          },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: '個人授權',
        to: '/assign',
        icon: 'cil-smile-plus',
        roles: ['admin'],
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['系統設定'],
        roles: ['admin'],
      },
      {
        _name: 'CSidebarNavItem',
        name: '帳號管理',
        to: '/users',
        icon: 'cil-user',
        roles: ['admin'],
      },
       /*
      {
        _name: 'CSidebarNavTitle',
        _children: ['Core UI']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Theme']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Colors',
        to: '/theme/colors',
        icon: 'cil-drop'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Typography',
        to: '/theme/typography',
        icon: 'cil-pencil'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Components']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Base',
        route: '/base',
        icon: 'cil-puzzle',
        items: [
          {
            name: 'Breadcrumbs',
            to: '/base/breadcrumbs',
            icon: 'cil-puzzle'
          },
          {
            name: 'Cards',
            to: '/base/cards',
            icon: 'cil-puzzle'
          },
          {
            name: 'Carousels',
            to: '/base/carousels',
            icon: 'cil-puzzle'
          }, 
          {
            name: 'Collapses',
            to: '/base/collapses',
            icon: 'cil-puzzle'
          },
          {
            name: 'Forms',
            to: '/base/forms',
            icon: 'cil-puzzle'
          },
          {
            name: 'Jumbotrons',
            to: '/base/jumbotrons',
            icon: 'cil-puzzle'
          },
          {
            name: 'List Groups',
            to: '/base/list-groups',
            icon: 'cil-puzzle'
          },
          {
            name: 'Navs',
            to: '/base/navs',
            icon: 'cil-puzzle'
          },
          {
            name: 'Navbars',
            to: '/base/navbars',
            icon: 'cil-puzzle'
          },
          {
            name: 'Paginations',
            to: '/base/paginations',
            icon: 'cil-puzzle'
          },
          {
            name: 'Popovers',
            to: '/base/popovers',
            icon: 'cil-puzzle'
          },
          {
            name: 'Progress Bars',
            to: '/base/progress-bars',
            icon: 'cil-puzzle'
          },
          {
            name: 'Switches',
            to: '/base/switches',
            icon: 'cil-puzzle'
          },
          {
            name: 'Tables',
            to: '/base/tables',
            icon: 'cil-puzzle'
          },
          {
            name: 'Tabs',
            to: '/base/tabs',
            icon: 'cil-puzzle'
          },
          {
            name: 'Tooltips',
            to: '/base/tooltips',
            icon: 'cil-puzzle'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Buttons',
        route: '/buttons',
        icon: 'cil-cursor',
        items: [
          {
            name: 'Buttons',
            to: '/buttons/standard-buttons',
            icon: 'cil-cursor'
          },
          {
            name: 'Button Dropdowns',
            to: '/buttons/dropdowns',
            icon: 'cil-cursor'
          },
          {
            name: 'Button Groups',
            to: '/buttons/button-groups',
            icon: 'cil-cursor'
          },
          {
            name: 'Brand Buttons',
            to: '/buttons/brand-buttons',
            icon: 'cil-cursor'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Charts',
        to: '/charts',
        icon: 'cil-chart-pie'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Icons',
        route: '/icons',
        icon: 'cil-star',
        items: [
          {
            name: 'CoreUI Icons',
            to: '/icons/coreui-icons',
            icon: 'cil-star',
            badge: {
              color: 'info',
              text: 'NEW'
            }
          },
          {
            name: 'Brands',
            to: '/icons/brands',
            icon: 'cil-star'
          },
          {
            name: 'Flags',
            to: '/icons/flags',
            icon: 'cil-star'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Notifications',
        route: '/notifications',
        icon: 'cil-bell',
        items: [
          {
            name: 'Alerts',
            to: '/notifications/alerts',
            icon: 'cil-bell'
          },
          {
            name: 'Badges',
            to: '/notifications/badges',
            icon: 'cil-bell'
          },
          {
            name: 'Modals',
            to: '/notifications/modals',
            icon: 'cil-bell'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Widgets',
        to: '/widgets',
        icon: 'cil-calculator',
        badge: {
          color: 'primary',
          text: 'NEW',
          shape: 'pill'
        }
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Extras']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Pages',
        route: '/pages',
        icon: 'cil-star',
        items: [
          {
            name: 'Login',
            to: '/pages/login',
            icon: 'cil-star'
          },
          {
            name: 'Register',
            to: '/pages/register',
            icon: 'cil-star'
          },
          {
            name: 'Error 404',
            to: '/pages/404',
            icon: 'cil-star'
          },
          {
            name: 'Error 500',
            to: '/pages/500',
            icon: 'cil-star'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Download CoreUI',
        href: 'http://coreui.io/vue/',
        icon: { name: 'cil-cloud-download', class: 'text-white' },
        _class: 'bg-success text-white',
        target: '_blank'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Try CoreUI PRO',
        href: 'http://coreui.io/pro/vue/',
        icon: { name: 'cil-layers', class: 'text-white' },
        _class: 'bg-danger text-white',
        target: '_blank'
      }
      */
    ]
  }
]