<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
       <el-avatar> {{displayName}} </el-avatar>
      </CHeaderNavLink>
    </template>
    <!--
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>帳號</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-bell"/> Updates
      <CBadge color="info" class="ml-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="ml-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="ml-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comments
      <CBadge color="warning" class="ml-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    -->
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>設定</strong>
    </CDropdownHeader>
    <CDropdownItem @click="changePassword">
      <CIcon name="cil-lock-locked"  /> 修改密碼
    </CDropdownItem>
    <!--
    <CDropdownItem>
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-settings" /> Settings
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-dollar" /> Payments
      <CBadge color="secondary" class="ml-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file" /> Projects
      <CBadge color="primary" class="ml-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    -->
    <CDropdownDivider/>
    <!--
    <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Lock Account
    </CDropdownItem>
    -->
    <CDropdownItem @click="logout">
      <CIcon name="cil-account-logout"  /> 登出
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapGetters } from 'vuex';
const ChangePassword = () => import('@/views/users/ChangePassword')
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42
    }
  },
  methods:{
    changePassword() {
      console.log('changePassword');
      this.$vuedals.open({
        name: '修改密碼',
        title: '',
        component: ChangePassword
      });
    },
    logout() {
      console.log('logout');

      this.$store.dispatch('auth/logout',null,{root: true}).then(res=>{
          this.$router.replace('/pages/login');
      });
    },
  },
    computed: {
      ...mapGetters({
          // 取得資料夾列表
          displayName: 'auth/displayName',
      }),
  },
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>