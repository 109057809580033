<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show.sync="show"
  >
  <!--
    <CSidebarBrand 
      :imgFull="{ width: 118, height: 46, alt: 'Logo', src: 'img/brand/coreui-base-white.svg'}"
      :imgMinimized="{ width: 118, height: 46, alt: 'Logo', src: 'img/brand/coreui-signet-white.svg'}"
      :wrappedInLink="{ href: 'https://coreui.io/', target: '_blank'}"
    />
    -->
    <CSidebarBrand />
    <CRenderFunction flat :content-to-render="activeNav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
export default {
  name: 'TheSidebar',
  data () {
    return {
      minimize: false,
      nav,
      show: 'responsive'
    }
  },
  computed: {
    activeNav: function() {
      return [{
        _name: 'CSidebarNav',
        _children: this.nav[0]._children.filter(item=>{
          
          if(item.roles && item.roles.length > 0) { 
            return  item.roles.includes(this.$store.state.auth.role);
          } else {
            return true;
          }
        })
      }]
    }
  },
  mounted () {
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })
  }
}
</script>
